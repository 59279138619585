import React, { FC } from 'react';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import { IframeProps } from './models';
import { toBoolean } from '../../utils/stringUtils/stringUtils';

const Iframe: FC<IframeProps> = ({ title, src, autoplay }) => (
  <iframe
    title={title}
    className="iframe lazyload"
    data-testid="iframe"
    allowFullScreen
    src={toBoolean(autoplay!) ? `${src}?autoplay=1` : src}
    allow="autoplay"
  />
);

export default Iframe;
