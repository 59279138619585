import React from 'react';
import UmbracoImage from 'common/Image/UmbracoImage';
import classNames from 'classnames';
import Iframe from 'common/Iframe';

const VideoWithThumbnail = ({
  image,
  setIsVisibleVideo,
  isVisibleVideo,
  imageAsBackgroundAlt,
  bannerVariant,
  src,
  title,
  autoplay,
  isPlayButton,
  className = '',
}) =>
  !isVisibleVideo ? (
    <div
      onClick={() => (!isPlayButton ? setIsVisibleVideo(true) : null)}
      className="banner--video__container"
      role="button"
      tabIndex={0}
    >
      {image ? (
        <UmbracoImage
          image={image}
          alt={imageAsBackgroundAlt!}
          className={classNames(
            'banner__image banner--with-image-as-background__image',
            className,
            {
              [`banner--${bannerVariant}__image`]: bannerVariant,
            }
          )}
        />
      ) : (
        <Iframe {...{ src: src!, title: title! }} />
      )}
      <div className="banner--video__container--cover" />
    </div>
  ) : (
    <Iframe {...{ src: src!, title: title!, autoplay }} />
  );

export default VideoWithThumbnail;
