import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from 'layout/Layout/Layout';
import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';
import Banner from 'common/Banner';
import VideoList from '../../components/VideoList';
import createBreadcrumbs from '../../utils/createBreadcrumbs';
import './tipsAndTricksVideos.scss';

const TipsAndTricksVideosPage: FC<TipsAndTricksVideosTypes.TipsAndTricksVideosProps> = ({
  data: {
    allUmbracoTipsAndTricksVideos: { nodes: tipsAndTricksVideosNodes },
    allUmbracoVideos: { nodes: umbracoVideosNodes },
    allSitePage: { nodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
    breadcrumbsCustomText,
  },
  location: { pathname },
}) => {
  const {
    langProps: { urls, lang },
    seoProps: { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs, ogImage },
    videosMainBanner: { content, bannerVariant, imageAsBackground, imageAsBackgroundAlt },
    videoListSettings,
  } = tipsAndTricksVideosNodes[0];
  const breadcrumbs = {
    breadCrumb: createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname),
    breadcrumbsCustomText,
  };

  return (
    <Layout breadcrumbs={breadcrumbs} className="tips-tricks-subpages" langProps={{ urls, lang }}>
      <Seo
        {...{
          title: seoMetaTitle,
          description: seoMetaDescription,
          keywords: seoMetaKeywords,
          externalHreflangs: seoExternalHreflangs,
          ogImage,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <Banner
        {...{
          content,
          bannerVariant,
          imageAsBackground,
          imageAsBackgroundAlt,
        }}
      />
      <VideoList {...{ videoListSettings, umbracoVideosNodes }} />
    </Layout>
  );
};

export const query = graphql`
  query TipsAndTricksVideosQuery($parentId: Int, $lang: String) {
    allUmbracoTipsAndTricksVideos(filter: { langProps: { lang: { eq: $lang } } }) {
      nodes {
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
          ogImage
        }
        videoListSettings {
          topText
          sectionTitle
          playVideoText
          moreAriaLabel
          numberOfVideos
        }
        videosMainBanner {
          content
          bannerVariant
          imageAsBackgroundAlt
          imageAsBackground {
            fallbackUrl
            fluid {
              srcSet
              originalImg
              base64
            }
            variants {
              url
              type
              fallbackQuery
              alias
            }
          }
        }
      }
    }
    allUmbracoVideos(filter: { parentId: { eq: $parentId }, langProps: { lang: { eq: $lang } } }) {
      nodes {
        langProps {
          lang
        }
        youtubeVideo
        videoImageAlt
        url
        title
        description
        videoCoverImage {
          fallbackUrl
          fluid {
            srcSet
            originalImg
            base64
          }
          variants {
            url
            type
            fallbackQuery
            alias
          }
        }
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default TipsAndTricksVideosPage;
